<template>
  <ChartContainer
    @input="init"
    :loading="loading"
    :showType="false"
    show-classes
  >
    <template v-slot:title>
      <span>{{
        $_t("admin.components.charts.teacher_survey_reports")
      }}</span></template
    >
    <template v-slot:default>
      <v-container fluid>
        <v-row v-if="surveys.length" class="justify-center">
          <v-col
            cols="12"
            v-for="s in surveys"
            :key="s.questionId"
            class="d-flex align-center justify-space-between"
          >
            <span class="text-h6 font-weight-bold">
              {{ `${questions[s.questionId]} (${s.average.toFixed(2)})` }}
            </span>
            <span>
              <v-rating
                half-increments
                v-model="s.average"
                color="primary"
                background-color="grey lighten-1"
                :length="5"
                large
                readonly
              >
              </v-rating>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </ChartContainer>
</template>

<script>
import ChartContainer from "@/packages/Reports/components/charts/ChartContainer";
import { mapGetters } from "vuex";
export default {
  name: "ReportTeachingSurvey",
  components: { ChartContainer },
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
    }),
  },
  data() {
    return {
      questions: {},
      surveys: [],
    };
  },
  methods: {
    async init({ classroomId = null }) {
      this.surveys = await this.$actions.getSurveyReport(
        this.$route.params.key,
        classroomId
      );
    },
    async getQuestions() {
      this.questions = await this.$actions.getSurveyQuestions();
    },
  },
  mounted() {
    this.getQuestions();
    this.init({});
  },
};
</script>

<style scoped></style>
