<template>
  <div>
    <ChartContainer :loading="loading" :showType="false">
      <template v-slot:title>
        <span>{{
          $_t("admin.components.charts.classroomReports")
        }}</span></template
      >
      <template v-slot:default>
        <v-expansion-panels style="background-color: transparent">
          <v-expansion-panel
            style="background-color: transparent"
            v-for="(info, index) in information"
            :key="index"
          >
            <v-expansion-panel-header>
              <v-card-title class="justify-space-between">
                <span>
                  {{ info.classroomName }} <br />
                  {{ `${$_t("attribute.teacher")}: ${info.teacherName}` }}
                </span>
                <span>
                  {{ $_t("attribute.presentationProgress") }}
                  <v-progress-circular
                    class="my-2"
                    :rotate="-90"
                    :size="55"
                    :width="10"
                    :value="info.presentationProgress"
                    :color="'#fff'"
                  >
                    {{ Math.round(info.presentationProgress) }}
                  </v-progress-circular>
                </span>
                <span>
                  {{ $_t("attribute.studentTaskProgress") }}
                  <v-progress-circular
                    class="my-2"
                    :rotate="-90"
                    :size="55"
                    :width="10"
                    :value="info.studentTaskProgress"
                    :color="'#fff'"
                  >
                    {{ Math.round(info.studentTaskProgress) }}
                  </v-progress-circular>
                </span>
              </v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card color="transparent">
                <v-card-title>{{ $_t("attribute.exams") }}</v-card-title>
                <v-simple-table style="background-color: transparent">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">{{ $_t("attribute.status") }}</th>
                        <th class="text-left">{{ $_t("attribute.score") }}</th>
                        <th class="text-left">
                          {{ $_t("attribute.destination") }}
                        </th>
                        <th class="text-left">
                          {{ $_t("attribute.exam_feedback") }}
                        </th>
                        <th class="text-left">
                          {{ $_t("attribute.exam_type") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(exam, i) in info.exams">
                        <tr v-if="exam.examFeedback" :key="i">
                          <td data-label="#">{{ i + 1 }}</td>
                          <td data-label="State">{{ exam.examState }}</td>
                          <td data-label="Score">
                            {{ exam.score || "No score reported" }}
                          </td>
                          <td data-label="Destination lesson">
                            {{ exam.destination }}
                          </td>
                          <td data-label="Exam feedback">
                            <ShowLessonDescription
                              :description="exam.examFeedback"
                              color="#fff"
                            />
                          </td>
                          <td data-label="Exam type">
                            {{ $_t(`attribute.examTypes.${exam.type}`) }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
                <hr />
                <!--          sessionReport-->
                <v-card-title>{{ $_t("attribute.homeworks") }}</v-card-title>
                <v-card
                  color="transparent"
                  v-for="(time, i) in info.sessionReport"
                  :key="i"
                  class="mb-6"
                >
                  <v-card-title class="justify-space-between">
                    {{
                      `Session: ${$_date(time.start * 1000)} => ${getMainTime(
                        time.start * 1000,
                        "h:mm a"
                      )} : ${getMainTime(
                        time.end * 1000,
                        "h:mm a"
                      )} - classroom: ${time.classroomNumber}`
                    }}
                    <span>
                      <ShowLessonDescription
                        :description="time.sessionFeedBack || ''"
                        color="#fff"
                        :text="$_t('attribute.sessionFeedBack')"
                      />
                    </span>
                    <span>
                      <ShowLessonDescription
                        :description="
                          time.sessionTeachedLessonsDescription || ''
                        "
                        color="#fff"
                        :text="
                          $_t('attribute.sessionTeachedLessonsDescription')
                        "
                      />
                    </span>
                  </v-card-title>
                  <v-simple-table style="background-color: transparent">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">#</th>
                          <th class="text-left">
                            {{ $_t("attribute.lessonName") }}
                          </th>
                          <th class="text-left">
                            {{ $_t("attribute.homeworkSubject") }}
                          </th>
                          <th class="text-left">
                            {{ $_t("attribute.homeworkFiles") }}
                          </th>
                          <th class="text-left">
                            {{ $_t("attribute.deadLine") }}
                          </th>
                          <th class="text-left">
                            {{ $_t("attribute.status") }}
                          </th>
                          <th class="text-left">
                            {{ $_t("attribute.doneMoment") }}
                          </th>
                          <th class="text-left">
                            {{ $_t("attribute.correctPercentation") }}
                          </th>
                          <th class="text-left">
                            {{ $_t("attribute.delay") }}
                          </th>
                          <th class="text-left">
                            {{ $_t("attribute.feedback") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="task in time.studentTasks">
                          <tr :key="task.lessonId">
                            <td data-label="#">{{ i + 1 }}</td>
                            <td data-label="#">{{ task.lessonName }}</td>
                            <td data-label="#">
                              <ShowLessonDescription
                                :description="task.homeworkSubject"
                                color="#fff"
                              />
                            </td>
                            <td>
                              <HomeWorkFilesModal
                                v-if="task.homeworkFiles?.length"
                                :files="task.homeworkFiles"
                              >
                                <template #activator>
                                  <v-btn color="primary">{{
                                    $_t("attribute.files")
                                  }}</v-btn>
                                </template>
                              </HomeWorkFilesModal>
                            </td>
                            <td data-label="#">
                              {{ $_date(task.homeworkDeadLine, "long") }}
                            </td>
                            <td data-label="State">{{ task.state }}</td>
                            <td data-label="State">
                              {{ $_date(task.doneMoment, "long") }}
                            </td>
                            <td data-label="State">
                              {{ task.correctPercentation }}
                            </td>
                            <td data-label="State">{{ task.delay }}</td>
                            <td data-label="State">
                              {{ task.homeworkFeedback }}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </ChartContainer>
  </div>
</template>

<script>
import ChartContainer from "@/packages/Reports/components/charts/ChartContainer";
import { mapGetters } from "vuex";
import { getMainTime } from "@/tools/Utils";
import ShowLessonDescription from "@/packages/admin/components/lessons/ShowLessonDescription";
import HomeWorkFilesModal from "@/packages/admin/components/classRoom/details/HomeWorkFilesModal";
export default {
  name: "ReportClassroomsOfStudent",
  components: { HomeWorkFilesModal, ShowLessonDescription, ChartContainer },
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
    }),
  },
  data() {
    return {
      information: [],
      getMainTime,
    };
  },
  props: {
    publicKey: {
      type: String,
      default: null,
    },
  },
  methods: {
    async init() {
      this.information = await this.$actions.getClassroomReportOfStudent({
        publicKey: this.$route.params.key || this.publicKey,
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
