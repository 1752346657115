<template>
  <div>
    <v-row>
      <v-col cols="12">
        <TopTitle :title="$_t('attribute.userReport')" />
      </v-col>
      <v-col cols="12">
        <v-card
          :loading="is_loading"
          class="mx-auto rounded-sm pa-5"
          width="100%"
          hover
          color="#2B2D3E"
          dark
        >
          <div
            class="d-flex flex-column justify-center align-center flex-sm-row justify-sm-space-between"
            v-if="user"
          >
            <span class="d-flex flex-column align-center">
              <span class="">Full name</span>
              <span class="font-weight-bold text-h4">{{ user.fullName }}</span>
            </span>
            <span class="d-flex flex-column align-center">
              <span class="">User name</span>
              <span class="text-h6">{{ user.userName }}</span>
            </span>
            <span class="d-flex flex-column align-center">
              <span class="">Nickname</span>
              <span class="text-h6"> {{ user.nickName }}</span>
            </span>
            <span class="d-flex flex-column align-center">
              <span class="">Role</span>
              <span class="text-h6"> {{ user.role }}</span>
            </span>
            <div>
              <div>
                <span class="">
                  <v-btn
                    outlined
                    dark
                    width="150px"
                    class="py-5"
                    @click="downloadPDF"
                    :disabled="is_loading"
                  >
                    {{ $_t("attribute.downloadPDF") }}
                    <v-icon class="pl-3">mdi-printer</v-icon>
                  </v-btn>
                </span>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <template v-if="user">
        <template v-if="user.role === 'Student'">
          <v-col cols="12">
            <ReportStudentAvgExamScore />
          </v-col>
          <v-col cols="12">
            <ReportClassroomsOfStudent />
          </v-col>
          <v-col cols="12">
            <ReportStudentExamScore ref="RSE" />
          </v-col>
          <v-col cols="12">
            <ReportHomeworksCompleted ref="test1" />
          </v-col>
        </template>
        <template>
          <v-col cols="12" v-if="user.role === 'Teacher'">
            <ReportTeacherClassPresentaion ref="presentation" />
          </v-col>
          <v-col
            cols="12"
            v-if="
              user.role === 'Teacher' &&
              ['Admin', 'TeamLeader'].includes(authRole)
            "
          >
            <ReportTeachingSurvey />
          </v-col>
          <v-col cols="12">
            <ReportTeachingProgress ref="test3" />
          </v-col>
          <v-col cols="12">
            <ReportReviewProgress ref="test4" />
          </v-col>
        </template>
      </template>
    </v-row>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import ReportStudentAvgExamScore from "@/packages/admin/components/reports/ReportStudentAvgExamScore";
import ReportStudentExamScore from "@/packages/admin/components/reports/ReportStudentExamScore";
import ReportHomeworksCompleted from "@/packages/admin/components/reports/ReportHomeworksCompleted";
import ReportTeachingProgress from "@/packages/admin/components/reports/ReportTeachingProgress";
import ReportReviewProgress from "@/packages/admin/components/reports/ReportReviewProgress";
import { mapGetters } from "vuex";
import { jsPDF } from "jspdf";
import ReportTeacherClassPresentaion from "@/packages/admin/components/reports/ReportTeacherClassPresentaion";
import ReportClassroomsOfStudent from "@/packages/admin/components/reports/ReportClassroomsOfStudent";
import ReportTeachingSurvey from "@/packages/admin/components/reports/ReportTeachingSurvey";
// import "./test/Arial-normal";
export default {
  name: "userReports",
  components: {
    ReportTeachingSurvey,
    ReportClassroomsOfStudent,
    ReportTeacherClassPresentaion,
    ReportReviewProgress,
    ReportTeachingProgress,
    ReportHomeworksCompleted,
    ReportStudentExamScore,
    ReportStudentAvgExamScore,
    TopTitle,
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      authRole: "authentication/role",
    }),
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    async downloadPDF() {
      const { imgURI1, imgURI2 } = await this.$refs.test3.downloadURI();
      const { imgURI3, imgURI4 } = await this.$refs.test4.downloadURI();
      const pdf = new jsPDF({
        orientation: "landscape",
      });

      pdf.setFont("Arial", "normal");
      pdf.text(
        `${this.user.fullName} ${this.user.nickName} | ${this.user.role}`,
        105,
        80,
        null,
        null,
        "center"
      );
      // const presentation =
      //   this.$refs.presentation?.getPresentationList() || null;
      // if (presentation) {
      //   const createHeaders = (keys) => {
      //     let result = [];
      //     for (let i = 0; i < keys.length; i += 1) {
      //       result.push({
      //         id: keys[i],
      //         name: keys[i],
      //         prompt: keys[i],
      //         width: 65,
      //         align: "center",
      //         padding: 0,
      //       });
      //     }
      //     return result;
      //   };
      //   const headers = createHeaders([
      //     "classroomName",
      //     "sessionDate",
      //     "lessonType",
      //     "level",
      //     "studentFullName",
      //     "isPresent",
      //   ]);
      //   pdf.addPage();
      //   pdf.text("Presentation list", 10, 10);
      //   pdf.table(
      //     10,
      //     30,
      //     presentation.map((p) => ({ ...p, isPresent: `${p.isPresent}` })),
      //     headers,
      //     { autoSize: true }
      //   );
      // }
      if (this.$refs.RSE) {
        const RSEUrl = await this.$refs.RSE.downloadURI();
        if (RSEUrl) {
          pdf.text("Student exams score", 10, 10);
          pdf.addImage(RSEUrl, "PNG", 0, 15);
        }
      }
      if (this.$refs.test1) {
        const url1 = await this.$refs.test1.downloadURI();
        if (url1) {
          pdf.addPage();
          pdf.text("Completed homeworks", 10, 10);
          pdf.addImage(url1, "PNG", 0, 15);
        }
      }

      if (this.$refs.test2) {
        const url2 = await this.$refs.test2.downloadURI();
        if (url2) {
          pdf.addPage();
          pdf.text("Pending homeworks", 10, 10);
          pdf.addImage(url2, "PNG", 0, 15);
        }
      }
      if (imgURI1) {
        pdf.addPage();
        pdf.text("Each Lesson Teaching Progress", 10, 10);
        pdf.addImage(imgURI1, "PNG", 0, 15);
      }
      if (imgURI2) {
        pdf.addPage();
        pdf.text("Each Title of Lessons Teaching Progress", 10, 10);
        pdf.addImage(imgURI2, "PNG", 0, 15);
      }

      if (imgURI3) {
        pdf.addPage();
        pdf.text("Each Lesson Reviewing Progress", 10, 10);
        pdf.addImage(imgURI3, "PNG", 0, 15);
      }

      if (imgURI4) {
        pdf.addPage();
        pdf.text("Each Title of Lessons Reviewing Progress", 10, 10);
        pdf.addImage(imgURI4, "PNG", 0, 15);
      }

      pdf.save(
        `report ${this.user.fullName}${this.$_date(new Date(), "long")}.pdf`
      );
    },
  },
  async created() {
    this.user = (
      await this.$actions.getUserByPublicKey(this.$route.params.key)
    ).user;
    await this.$actions.getClassroomsOfUser({
      publicKey: this.$route.params.key,
      dispatch: "class/setClassroomsOfUser",
    });
  },
};
</script>

<style scoped></style>
