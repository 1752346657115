<template>
  <div>
    <ChartContainer
      :loading="loading"
      :showType="false"
      show-classes
      @input="init"
      @from="init"
      @to="init"
      show-from-to
    >
      <template v-slot:title>
        <span>{{
          $_t("admin.components.charts.homeworks_report")
        }}</span></template
      >
      <template v-slot:default>
        <v-card-title>
          <v-row class="justify-center">
            <v-col cols="12" v-if="!empty">
              <apexchart
                ref="completedHomeworksReport"
                id="completedHomeworksReport"
                type="bar"
                :options="options"
                :series="series"
              ></apexchart>
            </v-col>
            <v-col v-else class="text-center">
              {{ $_t("attribute.empty_data") }}
            </v-col>
          </v-row>
        </v-card-title>
      </template>
    </ChartContainer>
  </div>
</template>

<script>
import ChartContainer from "@/packages/Reports/components/charts/ChartContainer";
import { mapGetters } from "vuex";
import moment from "moment";
import { _t, getMainTime } from "@/tools/Utils";

export default {
  name: "ReportHomeworksCompleted",
  components: { ChartContainer },
  data() {
    return {
      series: [],
      options: {},
      empty: false,
      from: null,
      to: null,
      lessonName: null,
      classroomId: null,
    };
  },
  props: {
    publicKey: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
    }),
  },
  methods: {
    async init({
      to = this.to,
      from = this.from,
      lessonName = this.lessonName,
      classroomId = this.classroomId,
    }) {
      this.series = [];
      this.to = to;
      this.from = from;
      this.lessonName = lessonName;
      this.classroomId = classroomId;
      const { data } = await this.$actions.getCompletedHomeworksReport(
        this.$route.params.key || this.publicKey,
        from,
        to,
        lessonName,
        classroomId
      );
      if (data.HomeworksCorrectPercentation.length === 0)
        return (this.empty = true);
      this.options = {
        chart: {
          id: "completedHomeworksReport",
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "top",
        },
        colors: [
          function ({ dataPointIndex, seriesIndex, w }) {
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            if (data?.delay !== 0) {
              return "#D9534F";
            } else {
              return "#008FFB";
            }
          },
        ],
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex, w }) {
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            return (
              "<ul class='pa-2'>" +
              `<li><b>${_t("attribute.state")}</b>: ` +
              data.homeWorkState +
              "</li>" +
              `<li><b>${_t("attribute.classroomName")}</b>: ` +
              data.classroomName +
              "</li>" +
              `<li><b>${_t("attribute.start_date")}</b>: ${moment(
                data.homeworkSessionDate.split("|")[0] * 1000
              ).format("YYYY-MM-DD, h:mm a")} => ${getMainTime(
                data.homeworkSessionDate.split("|")[0] * 1000,
                "h:mm a"
              )} : ${getMainTime(
                data.homeworkSessionDate.split("|")[1] * 1000,
                "h:mm a"
              )}</li>` +
              `<li><b>${_t("attribute.homework_subtitle")}</b>: ` +
              data.x +
              "</li>" +
              `<li><b>${_t("attribute.correctPercentation")}</b>: ` +
              data.y +
              "</li>" +
              `<li><b>${_t("attribute.deadline")}</b>: ` +
              `${moment(data.deadLine).fromNow()} at ${moment(
                data.deadLine
              ).format("YYYY-MM-DD, h:mm a")}` +
              "</li>" +
              `<li><b>${_t("attribute.doneMoment")}</b>: ` +
              `${moment(data.homeworkworkDoneMoment).fromNow()} at ${moment(
                data.homeworkworkDoneMoment
              ).format("YYYY-MM-DD, h:mm a")}` +
              "</li>" +
              `<li><b>${_t("attribute.delay")}</b>: ` +
              data.delay +
              " h" +
              "</li>" +
              "</ul>"
            );
          },
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 9000,
            options: {
              options: {
                plotOptions: {
                  bar: {
                    horizontal: false,
                  },
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          },
        ],
        xaxis: {
          type: "category",
        },
      };
      this.series.push({
        name: _t("admin.components.charts.completed_homeworks"),
        data: data.HomeworksDetails.map((item) => {
          return {
            ...item,
            x: item.homeworkLessonSubTitle,
            y: Math.floor(item.homeworksCorrectPercentation),
          };
        }),
      });
      console.log(this.series);
    },
    async downloadURI() {
      const { imgURI } =
        (await this.$refs.completedHomeworksReport?.chart?.dataURI()) || {
          imgURI: null,
        };
      return imgURI;
    },
  },
  created() {
    this.init({});
  },
};
</script>

<style scoped></style>
