<template>
  <div>
    <ChartContainer
      min-height="50px"
      show-classes
      :loading="loading"
      :type="type"
      @input="init"
    >
      <template v-slot:title>
        <span
          >{{ $_t("attribute.student_avg_exam_score") }} :
          {{ Math.round(studentAvgExamsScore) }} %</span
        >
      </template>
    </ChartContainer>
  </div>
</template>

<script>
import ChartContainer from "@/packages/Reports/components/charts/ChartContainer";
import { mapGetters } from "vuex";
export default {
  name: "ReportStudentAvgExamScore",
  components: { ChartContainer },
  data() {
    return {
      studentAvgExamsScore: "",
      type: "SubTitleExam",
      classroomId: null,
    };
  },
  props: {
    publicKey: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
    }),
  },
  methods: {
    async init({ type = this.type, classroomId = this.classroomId }) {
      this.type = type;
      this.classroomId = classroomId;
      const studentAvgExamsScore = await this.$actions.studentAvgExamsScore({
        type,
        key: this.$route.params.key || this.publicKey,
        classroomId,
      });
      this.studentAvgExamsScore = studentAvgExamsScore.toFixed(2);
    },
  },
  created() {
    this.init({});
  },
};
</script>

<style scoped></style>
