<template>
  <ChartContainer
    :loading="loading"
    :showType="false"
    @export="exportPresentation"
    show-export
  >
    <template v-slot:title>
      <span>{{
        $_t("admin.components.charts.teacher_classes_presentation")
      }}</span></template
    >
    <template v-slot:default>
      <v-container fluid>
        <v-row class="justify-center">
          <v-col cols="12">
            <v-simple-table style="background-color: #2b2d3e">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $_t("attribute.classroomName") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.date") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.baseType") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.level") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.fullName") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.isPresent") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(present, i) in presentations" :key="i">
                    <td data-label="Classroom name">
                      {{ present.classroomName }}
                    </td>
                    <td data-label="Date">{{ present.sessionDate }}</td>
                    <td data-label="lessonType">{{ present.lessonType }}</td>
                    <td data-label="level">{{ present.level }}</td>
                    <td data-label="Student name">
                      {{ present.studentFullName }}
                    </td>
                    <td data-label="Presentation">
                      <v-icon v-if="present.isPresent" color="green"
                        >mdi-check-outline</v-icon
                      >
                      <v-icon v-else color="red">mdi-close-outline</v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </ChartContainer>
</template>

<script>
import ChartContainer from "@/packages/Reports/components/charts/ChartContainer";
import { mapGetters } from "vuex";
import { utils, writeFileXLSX } from "xlsx";
export default {
  name: "ReportTeacherClassPresentaion",
  components: { ChartContainer },
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
    }),
  },
  data() {
    return {
      presentations: [],
    };
  },
  methods: {
    async init() {
      const data = await this.$actions.getStudentPresentationReport({
        teacherPublicKey: this.$route.params.key,
      });
      data.forEach((session) => {
        const { sessionDate } = session;
        const { classroomName, lessonType, level } =
          session.sessionAdditionalDetails;
        for (const student of session.students) {
          this.presentations.push({
            classroomName,
            sessionDate,
            lessonType: this.$_t(`attribute.${lessonType}`),
            level: this.$_t(`attribute.${level}`),
            studentFullName: student.studentName,
            isPresent: student.roll,
          });
        }
      });
    },
    exportPresentation() {
      const ws = utils.json_to_sheet(this.presentations);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, `Presentation.xlsx`);
    },
    getPresentationList() {
      return this.presentations.length ? this.presentations : null;
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped></style>
